import React from 'react';
import './style.scss';
import ScreensFloat from './sections/First/ScreensFloat/ScreensFloat';
import DownloadButtons from './sections/First/DownloadButtons/DownloadButtons';
import CopyCode from './sections/First/CopyCode/CopyCode';

import logo from '../assets/img/logo.png';
function NotAuthorized() {
  return (
    <section id="first" className="visible">
      <img src={logo} alt="likeawe" className="up-anim logo" />
      <h1>
        <strong>Não autorizado</strong>
      </h1>
      <p>
        Esse recurso só é acessível por celular e a partir de um{' '}
        <strong>
          QR Code de um <br />
          Embaixador Likeawe.
        </strong>
      </p>
      <p>
        Também só é permitido o uso
        <br /> <strong>1 vez por pessoa</strong>.
      </p>
      <aside>
        <ScreensFloat />
      </aside>
      <DownloadButtons isMobile={true} />
    </section>
  );
}

export default NotAuthorized;
