import React from 'react';
import './style.scss';
import circles from '../../../../assets/img/svg/circles.svg';
import screen1 from '../../../../assets/img/first/screen1.png';
import screen2 from '../../../../assets/img/first/screen2.png';
import ballon1 from '../../../../assets/img/first/ballon1.png';
import ballon2 from '../../../../assets/img/first/ballon2.png';
import ballon3 from '../../../../assets/img/first/ballon3.png';
const ScreensFloat = () => {
  const rand = () => {
    return Math.floor(Math.random() * 51) + 50;
  };

  return (
    <div className="orbit">
      <img src={circles} alt="circles" className="circles" />
    </div>
  );
};

export default ScreensFloat;
