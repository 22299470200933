import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import ShowCode from './ShowCode';

function Home() {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [ipLoaded, setIpLoaded] = useState(false);
  const [apiCalled, setApiCalled] = useState(false); // New state to control API call

  useEffect(() => {
    const loadIP = async () => {
      const ip = localStorage.getItem('user-ip');
      if (ip) {
        setIpLoaded(true);
      } else {
        try {
          const response = await axios.get(
            'https://api6.ipify.org?format=json'
          );
          const ip = response.data.ip;
          localStorage.setItem('user-ip', ip);
          setIpLoaded(true);
        } catch (error) {
          console.error('Error fetching IP:', error);
          setIpLoaded(true);
        }
      }
    };

    loadIP();
  }, []);

  useEffect(() => {
    if (!ipLoaded || apiCalled) return; // Ensure API is called only once

    const codeInvitation = localStorage.getItem('code-invitation');
    const myCode = localStorage.getItem('my-code');

    if (codeInvitation && !myCode) {
      setApiCalled(true);
      fetchCodeFromAPI(codeInvitation);
    } else {
      setLoading(false);
      setAuthorized(!!myCode);
    }
  }, [ipLoaded, apiCalled]);

  const fetchCodeFromAPI = async (code) => {
    const userIP = localStorage.getItem('user-ip');
    try {
      console.log('Tá chamando aqui também...');
      const response = await axios.get(
        `https://api.likeawe.com.br/indication/new-link-code/${code}`,
        {
          params: {
            ip: userIP,
          },
        }
      );
      if (response.status === 200) {
        localStorage.setItem('my-code', response.data.code);
        localStorage.setItem('name', response.data.name);
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    } catch (error) {
      setAuthorized(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return authorized ? <ShowCode /> : <Navigate to="/nao-autorizado" />;
}

export default Home;
