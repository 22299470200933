import React, { useEffect, useState, useRef } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom';
import axios from 'axios';
import Home from './components/Home';
import NotAuthorized from './components/NotAuthorized';

function App() {
  const navigate = useNavigate();
  const [ipLoaded, setIpLoaded] = useState(false);

  useEffect(() => {
    if (!isMobileDevice()) {
      navigate('/nao-autorizado');
    } else {
      fetchAndSaveIP();
    }
  }, [navigate]);

  const fetchAndSaveIP = async () => {
    try {
      const response = await axios.get('https://api6.ipify.org?format=json');
      const ip = response.data.ip;
      localStorage.setItem('user-ip', ip);
      setIpLoaded(true); // Set IP as loaded
    } catch (error) {
      console.error('Error fetching IP:', error);
      setIpLoaded(true); // Set IP as loaded even on error to avoid blocking
    }
  };

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const InviteHandler = () => {
    const { code } = useParams();
    const [apiCalled, setApiCalled] = useState(false);
    const apiCalledRef = useRef(apiCalled);
    let flagAPI = false;
    useEffect(() => {
      apiCalledRef.current = apiCalled;
    }, [apiCalled]);

    useEffect(() => {
      const handleInvite = async () => {
        if (!ipLoaded || apiCalledRef.current) return; // Wait until IP is loaded and prevent multiple calls
        setApiCalled(true);

        const myCode = localStorage.getItem('my-code');
        if (myCode) {
          navigate('/');
        } else {
          localStorage.setItem('code-invitation', code);
          if(flagAPI) return;
          await fetchCodeFromAPI(code); // Ensure this is awaited
        }
      };

      handleInvite();
    }, [code, navigate, ipLoaded]);

    const fetchCodeFromAPI = async (code) => {
      flagAPI = true;
      const userIP = localStorage.getItem('user-ip');
      try {
        console.log('Tá chamando aqui...');
        const response = await axios.get(
          `https://api.likeawe.com.br/indication/new-link-code/${code}`,
          {
            params: {
              ip: userIP,
            },
          }
        );

        if (response.status === 200) {
          localStorage.setItem('my-code', response.data.code);
          localStorage.setItem('name', response.data.name);
          navigate('/');
        } else {
          navigate('/nao-autorizado');
        }
      } catch (error) {
        navigate('/nao-autorizado');
      }
    };

    return null;
  };

  return (
    <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nao-autorizado" element={<NotAuthorized />} />
        <Route path="/invite/:code" element={<InviteHandler />} />
        <Route path="*" element={<Navigate to="/nao-autorizado" />} />
      </Routes>
    </main>
  );
}

export default App;
