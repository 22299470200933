import React, { useState } from 'react';
import DownloadButtons from './sections/First/DownloadButtons/DownloadButtons';
import './style.scss';
import ScreensFloat from './sections/First/ScreensFloat/ScreensFloat';
import CopyCode from './sections/First/CopyCode/CopyCode';

import logo from '../assets/img/logo.png';
function ShowCode() {
  const myCode = localStorage.getItem('my-code');
  const name = localStorage.getItem('name');
  const [isCopied, setIsCopied] = useState(false);


    const onCopy = () => {
      setIsCopied(true);
    };

  return (
    <section id="first" className="visible">
      <img src={logo} alt="likeawe" className="up-anim logo" />
      <h1>
        <strong>{name}</strong>, um embaixador likeawe te enviou um código de
        indicação
      </h1>
      <p>
        Utilize o meu código ao
        <br /> criar sua conta likeawe
      </p>
      <CopyCode myCode={myCode} onCopy={onCopy}/>
      <aside>
        <ScreensFloat />
      </aside>

      {isCopied ? <DownloadButtons isMobile={true} /> : ''}
    </section>
  );
}

export default ShowCode;
