import React, { useState } from 'react';
import './style.scss';

const CopyCode = ({ myCode, onCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(myCode)
        .then(() => {
          setIsCopied(true);
          // setTimeout(() => setIsCopied(false), 2000); // Reseta o estado após 2 segundos
          onCopy();
        })
        .catch((err) => {
          console.error('Falha ao copiar texto: ', err);
        });
    } else {
      // Método alternativo para dispositivos que não suportam a API do Clipboard
      const textArea = document.createElement('textarea');
      textArea.value = myCode;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setIsCopied(true);
        // setTimeout(() => setIsCopied(false), 2000); // Reseta o estado após 2 segundos
      } catch (err) {
        console.error('Falha ao copiar texto: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <span
        className={`code ${isCopied ? 'copied' : ''}`}
        onClick={copyToClipboard}
        role="button"
      >
        {myCode}
      </span>
      <p
        className={`txt ${isCopied ? 'copied' : ''}`}
        onClick={copyToClipboard}
      >
        {isCopied ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
          >
            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
          </svg>
        ) : ''}
        {!isCopied ? 'copiar código' : 'código copiado'}
      </p>
    </>
  );
};

export default CopyCode;
